
import { defineComponent, ref, watch,computed } from 'vue'
import moment, {Moment} from 'moment'
import type { PropType } from 'vue'
import useModel from '@/hooks/useModel'
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue'
import TypeClasses from '@/views/LocalRegistration/components/ApplicableTypeClassSelector2.vue'
import {calculationExecute, cancelCalculate, getCalculateDateFrom, getProcessList} from '@/API/localRegistration'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    BuSelector,
    TypeClasses
  },
  props: {
      visible: {
        type: Boolean,
        default: false
      },
      bu: {
        type: String as PropType<string>,
        default: '',
        required: true
      },
      currentRuleCode: {
        type: String as PropType<string>,
        default: '',
        required: true
      }
  },
  setup(props) {

    const visibleModel = useModel(props, 'visible')
    const typeClasses = ref<string[]>([])
    const startTime = ref<Moment>()
    const endTime = ref<Moment>()

    const unchangedBu = computed(()=> props.bu)


    const columns = [
      {
        title: 'BU',
        dataIndex: 'bu'
      },
      {
        title: 'Rt Date From',
        dataIndex: 'rtStartTime'
      },
      {
        title: 'To',
        dataIndex: 'rtEndTime'
      },
      {
        title: 'Process',
        slots: { customRender: 'Process' }
      },
      {
        title: 'Operation',
        align: 'center',
        slots: { customRender: 'Operation' }
      },
      {
        title: 'OT',
        dataIndex: 'ot'
      }
    ]

    const data = ref([])

    const handleCancel = () => {
      visibleModel.value = false
    }

    const noEmptyDate = () => {
      if (startTime.value === undefined || endTime.value === undefined){
        message.warning('Please select date!')
        return true
      }
      return false
    }

    const getProcess = () => {
      getProcessList({ruleCode: props.currentRuleCode}).then((res: any) => {
          if (typeClasses.value.length === 0){
            data.value = res
          } else {
            data.value = res.filter((item: any) => {
              return typeClasses.value.includes(item.typeClass)
            })
          }
      })
    }

    const calculatingResult = () => {
      if (noEmptyDate()) return
      const params = {
          "bulist": [
            props.bu
          ],
          "ruleCode": props.currentRuleCode,
          "endTime": endTime.value?.format('yyyy-MM-DD'),
          "startTime": startTime.value?.format('yyyy-MM-DD'),
      }
      calculationExecute(params).then((res: any) => {
        //TODO 有变动 只传bu 并且需要过滤 typeclass
        // if (typeClasses.value.length === 0){
        //   data.value = res
        // } else {
        //   data.value = res.filter((item: any) => {
        //     return typeClasses.value.includes(item.typeClass)
        //   })
        // }
        getProcess()
      })
    }

    const cancel = (record: any) => {
      const params = {
        id: record.id
      }
      cancelCalculate(params).then((res: any) => {
        message.success("The task has been marked as canceled, but the calculation process has not been terminated, please recalculate to get new results.")
        getProcess()
      })
    }

    const disabledStartDate = (current: Moment) => {
      if (endTime.value)
        return current && current > endTime.value;
    }
    const disabledEndDate = (current: Moment) => {
      if (startTime.value)
      {
          return current && current < startTime.value;

      }
    }


    const forbiddenDateFrom = ref(false)
    watch(() => props.visible, (newVisible) => {
      if (!newVisible) return
      getCalculateDateFrom({ruleCode : props.currentRuleCode}).then((res: any) => {
        if (res!== ''){
          startTime.value = moment(res)
          forbiddenDateFrom.value = true
        } else {
          startTime.value = undefined
          forbiddenDateFrom.value = false
        }
      })
      getProcess()
    }, {
      immediate:true
    })


    return {
      visibleModel,
      columns,
      data,
      handleCancel,
      typeClasses,
      startTime,
      endTime,
      calculatingResult,
      cancel,
      disabledStartDate,
      disabledEndDate,
      forbiddenDateFrom,
      unchangedBu
    }
  }
})
