
import { defineComponent, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue';
import {
    CheckCircleOutlined,
    FileSearchOutlined,
    CalculatorOutlined,
    DeleteOutlined,
} from '@ant-design/icons-vue';
import Calculate from '@/views/LocalRegistration/Setup/RateCalculationRuleSet/Calculate.vue';
import {
    getCalculationRuleList,
    deleteCalculationRule,
    updateCalculationRule,
} from '@/API/localRegistration';
import { message } from 'ant-design-vue';
import { debounce } from 'lodash-es';

type UndefinedString = undefined | string;

export default defineComponent({
    components: {
        BuSelector,
        CheckCircleOutlined,
        FileSearchOutlined,
        CalculatorOutlined,
        DeleteOutlined,
        Calculate,
    },
    setup() {
        const router = useRouter();

        const bu = ref<string>();
        const ruleName = ref<UndefinedString>(undefined);
        const ruleNameList = ref<any>([]);
        const ruleNameListAll = [
            '三方版',
            '行驶证版',
            '三方为主行驶证为辅版',
            '行驶证为主三方为辅版',
        ];
        const handleSearch = debounce((value: any) => {
            console.log('object', value, !value);
            if (!value) {
                ruleNameList.value = [];
                return;
            }
            ruleNameList.value = ruleNameListAll.filter((item: any) => {
                return item.includes(value);
            });
        }, 800);

        const ruleCode = ref('');
        // const getCalculationRuleSelectionList = (bu: string) => {
        //     getCalculationRuleSelection(bu).then((res: any) => {
        //         ruleNameList.value = res;
        //     });
        // };
        // watch(
        //     bu,
        //     (newBu: any) => {
        //         if (newBu) {
        //             getCalculationRuleSelectionList(newBu);
        //         }
        //     },
        //     {
        //         immediate: true,
        //     }
        // );

        const reset = () => {
            bu.value = undefined;
            ruleName.value = undefined;
            ruleCode.value = '';
        };

        const columns = [
            {
                title: 'Rule Code',
                dataIndex: 'ruleCode',
            },
            {
                title: 'Rule Name',
                dataIndex: 'ruleName',
            },
            {
                title: 'Registration data source',
                slots: {
                    customRender: 'Registration',
                },
            },
            {
                title: 'Applicable Exemption Case',
                dataIndex: 'exemptionCode',
                slots: { customRender: 'exemptionCode' },
            },
            {
                title: 'Special Deduction',
                align: 'center',
                slots: { customRender: 'SpecialDeduction' },
            },
            {
                title: 'Last Modified By',
                dataIndex: 'updatedBy',
            },
            {
                title: 'Last Modified Date',
                dataIndex: 'updatedAt',
            },
            {
                title: 'View',
                align: 'center',
                slots: { customRender: 'View' },
            },
            {
                title: 'Calculate',
                align: 'center',
                slots: { customRender: 'Calculate' },
            },
            {
                title: 'Operation',
                align: 'center',
                slots: { customRender: 'Operation' },
            },
            {
                title: 'Region Usage',
                align: 'center',
                slots: { customRender: 'RegionUsage' },
            },
        ];

        const data = ref<any>([
            // {
            //     licensePlate: 'R20210721012',
            //     ruleName: '行驶证版',
            //     registrationDateSource: '行驶证',
            //     applicableExemptionCase: 'E2021073101 XXXXXXXXXX',
            //     specialDeduction: '',
            //     lastModifiedBy: 'Dave Liu',
            //     lastModifiedDate: '2021-09-13',
            //     regionUsage: false,
            // },
        ]);

        const search = () => {
            const params = {
                bulist: [bu.value],
                ruleName: ruleName.value ?? '',
                ruleCode: ruleCode.value,
            };
            getCalculationRuleList(params).then((res: any) => {
                data.value = res.map((item: any) => {
                    if (item.usage === 'Y') {
                        return { ...item, regionUsage: true };
                    } else {
                        return { ...item, regionUsage: false };
                    }
                });
            });
        };

        const saveRegionUsageChange = (record: any) => {
            if (record.regionUsage) {
                record.usage = 'Y';
            } else {
                record.usage = 'N';
            }
            updateCalculationRule(record);
        };

        const deleteCurrentRow = (id: string) => {
            deleteCalculationRule({ id }).then((res: any) => {
                message.success('Delete Success!');
                search();
            });
        };

        const handleAdd = () => {
            router.push({
                path: '/localRegistration/setup/rateCalculationRuleSet/add',
                query: {
                    bu: bu.value,
                },
            });
        };

        const handleView = (record: any) => {
            router.push({
                path: `/localRegistration/setup/rateCalculationRuleSet/edit`,
                query: {
                    bu: record.value,
                    id: record.id,
                    code: record.ruleCode,
                    name: record.ruleName,
                    exemptionCode: record.exemptionCode,
                    dataSource: record.dataSource,
                    description: record.description,
                },
            });
        };

        const calculateDrawerVisible = ref(false);

        const currentRuleCode = ref('');
        const handleToCalculate = (record: any) => {
            currentRuleCode.value = record.ruleCode;
            calculateDrawerVisible.value = true;
        };

        const renderExceptionCode = (code: string) => {
            if (code === '') {
                return code;
            } else {
                return `${code}...`;
            }
        };

        const jumpToExemption = () => {
            router.push({
                name: 'Local Registration Data Upload Exemption',
            });
        };

        const renderRegistration = (record: any) => {
            const maps = {
                '1': '三方',
                '2': '行驶证',
                '3': '三方，行驶证',
                '4': '行驶证，三方',
            };
            return maps[record.dataSource];
        };

        return {
            columns,
            data,
            handleAdd,
            handleView,
            calculateDrawerVisible,
            handleToCalculate,
            bu,
            ruleName,
            ruleNameList,
            ruleCode,
            reset,
            search,
            deleteCurrentRow,
            saveRegionUsageChange,
            handleSearch,
            currentRuleCode,
            renderExceptionCode,
            renderRegistration,
            jumpToExemption,
        };
    },
});
